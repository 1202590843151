//// Ej 13

import Peliculas from "./Peliculas";
import Hero from "../components/Hero";
import "../App.css";

function Search() {
  return (
    <>
      <Hero page="Search" />
      <Peliculas />
    </>
  );
}

export default Search;
